(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrRouteDialogController", TrRouteDialogController);

    TrRouteDialogController.$inject = [
        "$timeout",
        "$scope",
        "$state",
        "entity",
        "TrRoute",
        "AllRouteTypes",
        "AllCustomRouteTypeAttribute",
        "RouteAttributeMappingId",
        "GeofenceIDs",
        'TrProject',
        'ParseLinks', 'AlertService',
        'pagingParams',
        'KmlToCoordinates',
        '$rootScope',
        'Configs'
    ];

    function TrRouteDialogController(
        $timeout,
        $scope,
        $state,
        entity,
        TrRoute,
        AllRouteTypes,
        AllCustomRouteTypeAttribute,
        RouteAttributeMappingId,
        GeofenceIDs,
        TrProject,
        ParseLinks,
        AlertService,
        pagingParams,
        KmlToCoordinates,
        $rootScope,
        Configs
    ) {
        var vm = this;
        vm.trRoute = entity;
        vm.save = save;
        var entity_old = angular.copy(vm.trRoute);
        vm.selectAttributes = selectedRouteTypeAttributes;
        vm.routeType = entity.routeType;
        vm.importRouteType = "";
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        var formData;
        vm.customAttributesMap = {};
        vm.customAttributesMapp = [];

        loadPage();
        var tempGeofenceId, coordinatesSpread, markersPoints, tempPointNumber, chainagePoints
        tempPointNumber = 0
        var locations = {};
        var coordinateList = []
        var markerList = []
        var chainageList = []
        var iconSize = 20
        var disVal = 0;

        if (!vm.trRoute.id) {
            vm.trRoute.routePoints = [];
            vm.isSaving = true

            
            vm.trRoute.status = "NEW"
            vm.trRoute.phase = "PLANNING"
        }

        function geoLocation(map) {
            var infoWindow = new google.maps.InfoWindow();
            var locationButton = document.createElement("button");
            locationButton.setAttribute("title", "Locate Me");
            locationButton.classList.add("custom-map-control-button");
            var geoIcon = document.createElement("i");
            geoIcon.classList.add("fa");
            geoIcon.classList.add("fa-crosshairs");
            geoIcon.classList.add("geo");
            locationButton.appendChild(geoIcon);
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
                locationButton
            );

            locationButton.addEventListener("click", function () {
                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");
                            infoWindow.open(map);
                            map.setCenter(pos);
                            map.setZoom(18);
                        },
                        function () {
                            handleLocationError(
                                true,
                                infoWindow,
                                map.getCenter()
                            );
                        },
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false, infoWindow, map.getCenter());
                }
            });

            function handleLocationError(
                browserHasGeolocation,
                infoWindow,
                pos
            ) {
                infoWindow.setPosition(pos);
                infoWindow.setContent(
                    browserHasGeolocation
                        ? "Error: The Geolocation service failed."
                        : "Error: Your browser doesn't support geolocation."
                );
                infoWindow.open(map);
            }
        }

        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
                {
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#EFEFEF",
                        },
                    ],
                }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles(),
            });
        }

        vm.closeModal = function (id) {
            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        }

        vm.openImport = function () {
            $(":file").filestyle({
                buttonBefore: true,
                buttonText: "Browse File",
            });

            vm.importxlsx = /^(.*?)\.(xlsx|kml)$/;
            $("#importRouter").show();
            vm.importRouteType = "";
        }

        vm.loadKmlOnMap = function (id) {
            formData = new FormData();

            formData.append("routename", vm.importRouteType);
            formData.append('file', $scope.myFile);

            KmlToCoordinates.getLatLng(formData, function (response, headers) {

                if (response.status == 200) {
                    vm.error = null;
                    vm.success = 'OK';

                    //vm.trRoute.routePoints = pointList
                    //vm.importRouteType = "";

                    if (vm.importRouteType == "COORDINATE") {
                        coordinatesSpread = response.data
                        vm.isSaving = false
                        var spreadCoordinates = response.data;
                        spreadCoordinates.forEach(function (latlng, index) {
                            tempPointNumber++
                            coordinateList.push({ routePointNumber: tempPointNumber, routePointType: vm.importRouteType, latitude: latlng.lat, longitude: latlng.lng , phase : vm.trRoute.phase});

                        });

                        if (spreadCoordinates.length > 0) {
                            for (var index in spreadCoordinates) {
                                if (index > 0) {
                                    disVal += distanceCal(
                                        spreadCoordinates[index]
                                            .lat,
                                        spreadCoordinates[index]
                                            .lng,
                                        spreadCoordinates[index - 1]
                                            .lat,
                                        spreadCoordinates[index - 1]
                                            .lng
                                    );
                                }
                            }
                        }
                        vm.trRoute.routeLength = parseInt(disVal*1000);

                    }

                    if (vm.importRouteType == "MARKER") {
                        markersPoints = response.data
                        response.data.forEach(function (latlng, index) {
                            tempPointNumber++
                            markerList.push({ routePointNumber: tempPointNumber, routePointType: vm.importRouteType, latitude: latlng.lat, longitude: latlng.lng ,phase : vm.trRoute.phase});

                        });
                    }

                    if (vm.importRouteType == "CHAINAGE") {
                        chainagePoints = response.data
                        //vm.isSaving = false

                        response.data.forEach(function (latlng, index) {
                            tempPointNumber++
                            chainageList.push({ routePointNumber: tempPointNumber, routePointType: vm.importRouteType, latitude: latlng.lat, longitude: latlng.lng,phase : vm.trRoute.phase });

                        });
                    }

                    $timeout(function () {
                        vm.success = '';
                    }, 4000);

                }
                if (response.status == 400) {
                    vm.success = null;
                    vm.error = 'error';

                }

                if (vm.importRouteType == "COORDINATE" && coordinatesSpread) {

                    locations.map.setCenter(coordinatesSpread[0]);

                    var polyline = new google.maps.Polyline({
                        path: coordinatesSpread,
                        geodesic: true,
                        strokeColor: '#FD7A24',
                        strokeOpacity: 1.0,
                        strokeWeight: 4,
                    });

                    // Set the map to display the polyline
                    polyline.setMap(locations.map);
                }

                if (vm.importRouteType == "MARKER" && markersPoints) {

                    locations.map.setCenter(markersPoints[0]);
                    markersPoints.forEach(function (each) {
                        var marker = new google.maps.Marker({
                            position: { lat: each.lat, lng: each.lng },
                            map: locations.map,
                            icon: {
                                scaledSize: new google.maps.Size(iconSize, iconSize),
                                url: "content/images/markersPoint.png",
                                anchor: new google.maps.Point(
                                    iconSize / 2,
                                    iconSize / 2
                                ),
                                labelOrigin: new google.maps.Point(25, 22),
                            },
                        });

                        markersPoints.push(marker);
                    })

                }

                if (vm.importRouteType == "CHAINAGE" && chainagePoints) {


                    locations.map.setCenter(chainagePoints[0]);
                    chainagePoints.forEach(function (each) {
                        var marker = new google.maps.Marker({
                            position: { lat: each.lat, lng: each.lng },
                            map: locations.map,
                            icon: {
                                scaledSize: new google.maps.Size(iconSize, iconSize),
                                url: "content/images/chainagePoints.png",
                                anchor: new google.maps.Point(
                                    iconSize / 2,
                                    iconSize / 2
                                ),
                                labelOrigin: new google.maps.Point(25, 22),
                            },
                        });

                        chainagePoints.push(marker);
                    })

                }

            });
            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        }

        if (vm.trRoute.id != null) {
            vm.trRouteType = vm.trRoute.routeType;
        }

        function distanceCal(lat1, lon1, lat2, lon2) {
            var theta = lon1 - lon2;
            var dist =
                Math.sin(degrees_to_radians(lat1)) *
                Math.sin(degrees_to_radians(lat2)) +
                Math.cos(degrees_to_radians(lat1)) *
                Math.cos(degrees_to_radians(lat2)) *
                Math.cos(degrees_to_radians(theta));
            dist = Math.acos(dist);
            dist = radians_to_degrees(dist);
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344;
            if (isNaN(dist)) {
                return 0.0;
            } else {
                return dist;
            }
        }

        function degrees_to_radians(degrees) {
            var pi = Math.PI;
            return degrees * (pi / 180);
        }

        function radians_to_degrees(radians) {
            var pi = Math.PI;
            return radians * (180 / pi);
        }

        function loadPage() {

            TrProject.query(
                {
                    page: 0,
                    size: 100,
                    sort: sort(),
                },
                onSuccess,
                onError
            );

            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    //  result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.projects = [];
                data.forEach(function (value) {
                    if (value.zone) {
                        value.type = "MASTER";
                    } else {
                        value.type = "MINI";
                    }
                    vm.projects.push(value);
                });
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

            vm.imageInNewTab = function (img) {
                var imgWindow = window.open();
                imgWindow.document.write(
                    '<img width="25%"   src="data:image/jpeg;base64,' +
                    img +
                    '">'
                );
                imgWindow.document.close();
            };

            var routeTypeAttributeValuesList =
                vm.trRoute.routeTypeAttributeValues;

            try {
                for (var index in vm.trRoute.routeType.routeTypeAttribute) {
                    if (
                        !checkIfRouteTypeExists(
                            vm.trRoute.routeType.routeTypeAttribute[index],
                            vm.trRoute.routeTypeAttributeValues
                        )
                    ) {
                        var routeTypeAttribute1 =
                            vm.trRoute.routeType.routeTypeAttribute[index];
                        routeTypeAttributeValuesList.push({
                            routeTypeAttribute: routeTypeAttribute1,
                            attributeValue:
                                vm.trRoute.routeType.routeTypeAttribute[index]
                                    .defaultValue,
                        });
                    }
                }
            } catch (e) { }

            function checkIfRouteTypeExists(routeType, routeTypeAttrVal) {
                var hasRouteType = false;
                for (var i = 0; i < routeTypeAttrVal.length; i++) {
                    var name = routeTypeAttrVal[i].routeTypeAttribute.name;
                    if (name == routeType.name) {
                        hasRouteType = true;
                        break;
                    }
                }
                return hasRouteType;
            }
            AllRouteTypes.getAll(function (response) {
                vm.routeTypes = response;
            });

            AllCustomRouteTypeAttribute.getAll(function (response) {
                //vm.customRouteTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    if (arrayItem.customRouteTypeAttributeValues != undefined) {
                        vm.customAttributesMap[arrayItem.name] =
                            arrayItem.customRouteTypeAttributeValues;
                        vm.customAttributesMap[arrayItem.name].parentId =
                            arrayItem.id;
                    }

                });
                if (vm.trRoute.id) {
                    $(":file").filestyle({
                        buttonBefore: true,
                    });
                    $(":file").filestyle("buttonText", "Browse File");
                }
            });


        }
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function selectedRouteTypeAttributes() {
            vm.showFilter = true;
            if (vm.routeType) {
                vm.trRoute.routeType = vm.routeType;
                if (
                    entity_old.routeType &&
                    vm.routeType.id === entity_old.routeType.id
                ) {
                    vm.trRoute.routeTypeAttributeValues = [];
                    vm.trRoute.routeTypeAttributeValues =
                        entity_old.routeTypeAttributeValues;
                } else {
                    vm.trRoute.routeTypeAttributeValues = [];
                    $.each(
                        vm.routeType.routeTypeAttribute,
                        function (key, value) {
                            vm.trRoute.routeTypeAttributeValues.push({
                                routeTypeAttribute:
                                    vm.routeType.routeTypeAttribute[key],
                            });
                        }
                    );
                }
            }
        }

        function save() {
            //vm.isSaving = true;
            /* vm.trRoute.routePoints = path.getArray().map(function(latLng) {
                return {
                  latitude: latLng.lat(),
                  longitude: latLng.lng()
                };
              }); */
            /* var spreadAV = vm.trRoute.routeTypeAttributeValues;
            for (
                var spreadAssetIndex = 0;
                spreadAssetIndex < spreadAV.length;
                spreadAssetIndex++
            ) {
                if (
                    spreadAV[spreadAssetIndex].routeTypeAttribute
                        .name === "Fiber Distance (km)"
                ) {
                    spreadAV[
                        spreadAssetIndex
                    ].attributeValue = fiberDistance;
                }
            }
            vm.trRoute.routeTypeAttributeValues = spreadAV; */

            if ((!vm.trRoute.id) && (markerList || coordinateList || chainageList)) {
                vm.trRoute.routePoints = (coordinateList || []).concat(markerList || [], chainageList || []);
            }


            if (vm.trRoute.id !== null) {
                TrRoute.update(vm.trRoute, onSaveSuccess, onSaveError);
            } else {
                TrRoute.save(vm.trRoute, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:routeUpdate", result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            if (pageValue == "user-management.new") {
                $state.go("user-management.new");
            } else {
                $state.go("tr-route");
            }
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        $scope.geofencelabel = {
            search: "Search",
            nothingSelected: "Geofence",
        };

        /*  GeofenceIDs.getAll(function (response) {
             vm.geofencesListView = []
             if ($scope.tab == 1) {
                 if (response.length >= 2) {
                     vm.geofencesListView.push({
                         id: 0,
                         name: "All",
                         label: "All",
                     });
                 }
             }
             response.forEach(function (geofence) {
                 geofence.label = geofence.name.substring(0, 15) + "..";
                 geofence.ticked =
                     tempGeofenceId == geofence.id ? true : false;
                 vm.geofencesListView.push(geofence);
                 if (vm.selectedMapGeofenceId == null) {
                     vm.selectedMapGeofenceId = geofence.id;
                 }
             });
             vm.countLength = vm.geofencesListView.length;
         }); */

        locations.map = new google.maps.Map(
            document.getElementById("map_canvas"),
            {
                center: {
                    lat: parseFloat($rootScope.DEFAULT_LATITUDE),
                    lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
                },
                zoom: 13,
                fullscreenControl: true,
                styles: mapStyles(),
            }
            //myOption(16, vm.getCenterEnd)
        );
        //geoLocation(locations.map);
        //vm.getCenterEnd = locationsEnd.map.center;

        /* vm.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYLINE,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ["polyline"],
            },
            polylineOptions: {
                fillColor: "#ffff00",
                fillOpacity: 1,
                strokeWeight: 5,
                clickable: true,

                editable: true,
                zIndex: 1,
                geodesic: true,
            },
        });

        

        var polyline,path
        google.maps.event.addListener(vm.drawingManager, 'overlaycomplete', function(event) {
            if (event.type == google.maps.drawing.OverlayType.POLYLINE) {
              // Get the path of the drawn polyline
              polyline = event.overlay;
              path = polyline.getPath();
          
              
            }
          });

          vm.drawingManager.setMap(locations.map);
      
        vm.createfence = function(){
            if (polyline) {
                path = polyline.getPath();
          
                var coordinates = path.getArray().map(function(latLng) {
                  return {
                    lat: latLng.lat(),
                    lng: latLng.lng()
                  };
                });
          
                console.log('Polyline coordinates:', coordinates);
                // You can use the coordinates in any way you need here
              } 
        } */


        if (vm.trRoute.id && vm.trRoute.routePoints != null && vm.trRoute.routePoints.length) {
            vm.trRoute.routePoints.sort(function(a, b) { return a.routePointNumber - b.routePointNumber});

            vm.trRoute.routePoints.forEach(function (each) {
                if (each.routePointType == "COORDINATE") {
                    coordinateList.push({ lat: each.latitude, lng: each.longitude })
                } else if (each.routePointType == "MARKER") {
                    markerList.push({ lat: each.latitude, lng: each.longitude })
                } else if (each.routePointType == "CHAINAGE") {
                    chainageList.push({ lat: each.latitude, lng: each.longitude })
                }
            })

            if (coordinateList.length) {
                locations.map.setCenter(coordinateList[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateList,
                    geodesic: true,
                    strokeColor: '#FD7A24',
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
            }

            if (markerList.length) {
                locations.map.setCenter(markerList[0]);
                createMarkers(markerList, 'content/images/markersPoint.png', locations.map)
            }

            if (chainageList.length) {
                locations.map.setCenter(chainageList[0]);
                createMarkers(chainageList, 'content/images/chainagePoints.png', locations.map)
            }
        }

        function createMarkers(arr, img, map) {
            arr.forEach(function (each) {
                var marker = new google.maps.Marker({
                    position: { lat: each.lat, lng: each.lng },
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: img,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                });

                arr.push(marker);
            })
        }

        $('.select2').select2();
 
        Configs.get("Phases", function (response) {
            vm.workFlowPhase = response;
        });

        Configs.get("RolloutTaskStatus", function (response) {
            vm.rolloutTaskStatus = response;
        });

    }
})();
