(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrSectionDialogController", TrSectionDialogController);

    TrSectionDialogController.$inject = [
        "$timeout",
        "$scope",
        "$state",
        "entity",
        "TrSection",
        "AllSectionTypes",
        "AllCustomSectionTypeAttribute",
        "Configs",
        "route",
        "postRoutePoints",
        "AlertService",
        "$window",
        "$rootScope"
    ];

    function TrSectionDialogController(
        $timeout,
        $scope,
        $state,
        entity,
        TrSection,
        AllSectionTypes,
        AllCustomSectionTypeAttribute,
        Configs,
        route,
        postRoutePoints,
        AlertService,
        $window,
        $rootScope
    ) {
        var vm = this;
        vm.trSection = entity;
        vm.save = save;
        var entity_old = angular.copy(vm.trSection);
        vm.selectAttributes = selectedSectionTypeAttributes;
        vm.sectionType = entity.sectionType;
        var markers = []
        var coordinateList = []
        var chainageList = []
        var markerList = []
        var iconSize = 20
        var labelFrom, labelTo
        labelFrom = 'From Section'
        labelTo = 'To Section'
        vm.isSetUpdate = false
        var infowindow = new google.maps.InfoWindow();
        var openedInfoWindow = null;
        var btnAddSection;

        if ($state.params.routeId) {
            vm.trSection.routeId = parseInt($state.params.routeId, 10);
        }

        vm.customAttributesMap = {};
        vm.customAttributesMapp = [];
        loadPage();
        var locations = {};
        var locationsEnd = {};


        if (vm.trSection.id != null) {
            vm.trSectionType = vm.trSection.sectionType;
        }

        if (!vm.trSection.id) {
            vm.trSection.routeName = route.name
            vm.trSection.status = "NEW"
            vm.trSection.phase = "PLANNING"
        }


        Configs.get("RolloutTaskStatus", function (response) {
            vm.rolloutTaskStatus = response;
        });

        function loadPage() {

            vm.imageInNewTab = function (img) {
                var imgWindow = window.open();
                imgWindow.document.write(
                    '<img width="25%"   src="data:image/jpeg;base64,' +
                    img +
                    '">'
                );
                imgWindow.document.close();
            };

            var sectionTypeAttributeValuesList =
                vm.trSection.sectionTypeAttributeValues;

            try {
                for (var index in vm.trSection.sectionType.sectionTypeAttribute) {
                    if (
                        !checkIfSectionTypeExists(
                            vm.trSection.sectionType.sectionTypeAttribute[index],
                            vm.trSection.sectionTypeAttributeValues
                        )
                    ) {
                        var sectionTypeAttribute1 =
                            vm.trSection.sectionType.sectionTypeAttribute[index];
                        sectionTypeAttributeValuesList.push({
                            sectionTypeAttribute: sectionTypeAttribute1,
                            attributeValue:
                                vm.trSection.sectionType.sectionTypeAttribute[index]
                                    .defaultValue,
                        });
                    }
                }
            } catch (e) { }

            function checkIfSectionTypeExists(sectionType, sectionTypeAttrVal) {
                var hasSectionType = false;
                for (var i = 0; i < sectionTypeAttrVal.length; i++) {
                    var name = sectionTypeAttrVal[i].sectionTypeAttribute.name;
                    if (name == sectionType.name) {
                        hasSectionType = true;
                        break;
                    }
                }
                return hasSectionType;
            }
            AllSectionTypes.getAll(function (response) {
                vm.sectionTypes = response;
            });

            AllCustomSectionTypeAttribute.getAll(function (response) {
                //vm.customSectionTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    if (arrayItem.customSectionTypeAttributeValues != undefined) {
                        vm.customAttributesMap[arrayItem.name] =
                            arrayItem.customSectionTypeAttributeValues;
                        vm.customAttributesMap[arrayItem.name].parentId =
                            arrayItem.id;
                    }

                });
                if (vm.trSection.id) {
                    $(":file").filestyle({
                        buttonBefore: true,
                    });
                    $(":file").filestyle("buttonText", "Browse File");
                }
            });


        }
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function selectedSectionTypeAttributes() {
            vm.showFilter = true;
            if (vm.sectionType) {
                vm.trSection.sectionType = vm.sectionType;
                if (
                    entity_old.sectionType &&
                    vm.sectionType.id === entity_old.sectionType.id
                ) {
                    vm.trSection.sectionTypeAttributeValues = [];
                    vm.trSection.sectionTypeAttributeValues =
                        entity_old.sectionTypeAttributeValues;
                } else {
                    vm.trSection.sectionTypeAttributeValues = [];
                    $.each(
                        vm.sectionType.sectionTypeAttribute,
                        function (key, value) {
                            vm.trSection.sectionTypeAttributeValues.push({
                                sectionTypeAttribute:
                                    vm.sectionType.sectionTypeAttribute[key],
                            });
                        }
                    );
                }
            }
        }

        function save() {
            //vm.isSaving = true;
            //var spreadAV = vm.trSection.sectionTypeAttributeValues;
            /*  for (
                 var spreadAssetIndex = 0;
                 spreadAssetIndex < spreadAV.length;
                 spreadAssetIndex++
             ) {
                 if (
                     spreadAV[spreadAssetIndex].sectionTypeAttribute
                         .name === "Fiber Distance (km)"
                 ) {
                     spreadAV[
                         spreadAssetIndex
                     ].attributeValue = fiberDistance;
                 }
             }
             vm.trSection.sectionTypeAttributeValues = spreadAV; */
            callTask()
            /*   if (vm.trSection.id !== null) {
  
                  if(vm.isSetUpdate){
                      if(markers.length == 2){
                          var payload
                          markers.forEach(function (item){
          
                              if(item.label.text == labelFrom || item.label.text == labelTo){
                                  payload = { "latitude" : item.position.lat(), "longitude" : item.position.lng()}
                                  postRoutePoints.post(payload,function(response){
          
                                      if(item.label.text == labelFrom){
                                          vm.trSection.fromRoutePoint = {
                                              id: response.id,
                                          };
                                      }
          
                                      if(item.label.text == labelTo){
                                          vm.trSection.toRoutePoint = {
                                              id: response.id,
                                          };
          
                                          if(vm.trSection.fromRoutePoint && vm.trSection.toRoutePoint){
                                              setTimeout(function(){ TrSection.update(vm.trSection, onSaveSuccess, onSaveError); }, 1500);
                                              
                                          }else{
                                              AlertService.error("Error");
                                          }
                                          
                                      }
                                     
          
                                     
                                  },function(error){
                                      AlertService.error(error.data.message);
                                  })
                              }
                          })
                      }else{
                          alert("Section From or To Points are Empty")
                      }
                  }else{
                      if(markers.length == 2){
                          TrSection.update(vm.trSection, onSaveSuccess, onSaveError);
                      }else{
                         alert("Section From or To Points are Empty")
                      }
                  }
                  
              } else{
                  if(markers.length == 2){
                      var payload
                      markers.forEach(function (item){
      
                          if(item.label.text == labelFrom || item.label.text == labelTo){
                              payload = { "latitude" : item.position.lat(), "longitude" : item.position.lng()}
                              postRoutePoints.post(payload,function(response){
      
                                  if(item.label.text == labelFrom){
                                      vm.trSection.fromRoutePoint = {
                                          id: response.id,
                                      };
                                  }
      
                                  if(item.label.text == labelTo){
                                      vm.trSection.toRoutePoint = {
                                          id: response.id,
                                      };
                                      callTrsectionSave()
                                  }
                                 
      
                                 
                              },function(error){
                                  AlertService.error(error.data.message);
                              })
                          }
                      })
                  }else{
                      alert("Section From or To Points are Empty")
                  }
              } */


        }

        /*   function callTrsectionSave(){
              if(vm.trSection.fromRoutePoint && vm.trSection.toRoutePoint){
                  setTimeout(function(){ TrSection.save(vm.trSection, onSaveSuccess, onSaveError); }, 2500);
              }else{
                  AlertService.error("Error");
              }
          }
  
          function onSaveSuccess(result) {
              $scope.$emit("trakeyeApp:sectionUpdate", result);
              //vm.isSaving = false;
  
              var pageValue = sessionStorage.getItem("prevPage");
              if (pageValue == "user-management.new") {
                  $state.go("user-management.new");
              } else {
                  $window.history.back();
              }
          }
  
          function onSaveError() {
              //vm.isSaving = false;
          } */

        function callTask() {
            if (markers.length == 2) {
                markers.forEach(function (item) {
                    if (item.label.text == labelFrom || item.label.text == labelTo) {
                        if (item.label.text == labelFrom) {
                            if (vm.trSection.id) {
                                vm.trSection.fromRoutePoint.latitude = item.position.lat()
                                vm.trSection.fromRoutePoint.longitude = item.position.lng()
                            } else {
                                vm.trSection.fromRoutePoint = {
                                    "latitude": item.position.lat(), "longitude": item.position.lng()
                                };
                            }
                        }

                        if (item.label.text == labelTo) {
                            if (vm.trSection.id) {
                                vm.trSection.toRoutePoint.latitude = item.position.lat()
                                vm.trSection.toRoutePoint.longitude = item.position.lng()
                            } else {
                                vm.trSection.toRoutePoint = {
                                    "latitude": item.position.lat(), "longitude": item.position.lng()
                                };
                            }
                        }
                    }
                })

                markersNearby.forEach(function (item) {
                        if (item.label.text == 'From Nearby') {
                            if (vm.trSection.id) {
                                vm.trSection.fromNearestPoint.latitude = item.position.lat()
                                vm.trSection.toNearestPoint.longitude = item.position.lng()
                            } else {
                                vm.trSection.fromNearestPoint = {
                                    "latitude": item.position.lat(), "longitude": item.position.lng()
                                };
                            }
                        }

                        if (item.label.text == 'To Nearby') {
                            if (vm.trSection.id) {
                                vm.trSection.fromNearestPoint.latitude = item.position.lat()
                                vm.trSection.toNearestPoint.longitude = item.position.lng()
                            } else {
                                vm.trSection.toNearestPoint = {
                                    "latitude": item.position.lat(), "longitude": item.position.lng()
                                };
                            }
                        }
                })
                if (vm.trSection.id) {
                    TrSection.update(vm.trSection, onSaveSuccess, onSaveError)
                } else {
                    TrSection.save(vm.trSection, onSaveSuccess, onSaveError)
                }
            } else {
                alert("Section From or To Points are Empty")
            }
        }

        function onSaveSuccess(result) {
            $window.history.back();
        }

        function onSaveError() {
        }

        Configs.get("Phases", function (response) {
            vm.workFlowPhase = response;
            //vm.workFlow.phase = "Planning Phase"
            /*  if(vm.workFlow.id == null){
                response.forEach(function(val){
                    if(val.configValue == '01'){
                        vm.workFlow.phase = val.configKey
                    }
                })
            }*/
        });

        //start

        locations.map = new google.maps.Map(
            document.getElementById("map_canvasStart"),
            {
                center: {
                    lat: parseFloat($rootScope.DEFAULT_LATITUDE),
                    lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
                },
                zoom: $rootScope.rolloutMapInitZoom,
                fullscreenControl: true,
                styles: mapStyles(),
            }
            //myOption(16, vm.getCenterStart)
        );


        google.maps.event.addListener(locations.map, "click", function (event) {
            if (markers.length < 2) {
                // Add a marker to the map
                addMarker(event.latLng);
            } else {
                console.log("Maximum 2 markers allowed.");
            }
        });
        //geoLocation(locations.map);

        //vm.getCenterStart = locations.map.center;

        //end
        /* 
        locationsEnd.map = new google.maps.Map(
            document.getElementById("map_canvasEnd"),
            {
                center: {
                    lat: parseFloat($rootScope.DEFAULT_LATITUDE),
                    lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
                },
                zoom: 13,
                fullscreenControl: true,
                styles: mapStyles(),
            }
            //myOption(16, vm.getCenterEnd)
        );
        geoLocation(locationsEnd.map); */
        //vm.getCenterEnd = locationsEnd.map.center;

        function geoLocation(map) {
            var infoWindow = new google.maps.InfoWindow();
            var locationButton = document.createElement("button");
            locationButton.setAttribute("title", "Locate Me");
            locationButton.classList.add("custom-map-control-button");
            var geoIcon = document.createElement("i");
            geoIcon.classList.add("fa");
            geoIcon.classList.add("fa-crosshairs");
            geoIcon.classList.add("geo");
            locationButton.appendChild(geoIcon);
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
                locationButton
            );

            var goTo = document.createElement("span");
            goTo.setAttribute("title", "Location Coordinates");
            goTo.classList.add("custom-map-control-button");
            var gotoIcon = document.createElement("i");
            gotoIcon.classList.add("fa");
            gotoIcon.classList.add("fa-location-arrow");
            gotoIcon.classList.add("geo");
            gotoIcon.classList.add("mt-5");
            goTo.appendChild(gotoIcon);
            map.controls[google.maps.ControlPosition.RIGHT_TOP].push(goTo);
            goTo.addEventListener("click", function () {
                vm.removeMarker()
                $("#modalLatLongs").show();
            });
            
            var goToNearBy = document.createElement("span");
            goToNearBy.setAttribute("title", "Add Nearby Points");
            goToNearBy.classList.add("custom-map-control-button");
            var goToNearByIcon = document.createElement("i");
            goToNearByIcon.classList.add("fa");
            goToNearByIcon.classList.add("fa-thumb-tack");
            goToNearByIcon.classList.add("geo");
            goToNearByIcon.classList.add("mt-10");
            goToNearBy.appendChild(goToNearByIcon);
            map.controls[google.maps.ControlPosition.LEFT_TOP].push(goToNearBy);
            goToNearBy.addEventListener("click", function () {
                //vm.removeMarker()
                vm.isShowNearbyAdd = true
            });

            locationButton.addEventListener("click", function () {
                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");
                            infoWindow.open(map);
                            map.setCenter(pos);
                            map.setZoom(18);
                        },
                        function () {
                            handleLocationError(
                                true,
                                infoWindow,
                                map.getCenter()
                            );
                        },
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false, infoWindow, map.getCenter());
                }
            });

            function handleLocationError(
                browserHasGeolocation,
                infoWindow,
                pos
            ) {
                infoWindow.setPosition(pos);
                infoWindow.setContent(
                    browserHasGeolocation
                        ? "Error: The Geolocation service failed."
                        : "Error: Your browser doesn't support geolocation."
                );
                infoWindow.open(map);
            }
        }

        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
                {
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#EFEFEF",
                        },
                    ],
                }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles(),
            });
        }

        vm.removeMarker = function () {
            markers.forEach(function (marker) {
                marker.setMap(null);
            });

            // Clear the markers array
            markers = [];

            markersNearby.forEach(function (marker) {
                marker.setMap(null);
            });

            // Clear the markers array
            markersNearby = [];

            vm.isShowNearbyAdd = false
            if (vm.trSection.id) {
                vm.isSetUpdate = true
            }

        }

        if (route && route.routePoints != null && route.routePoints.length) {
            route.routePoints.sort(function(a, b) { return a.routePointNumber - b.routePointNumber});

            route.routePoints.forEach(function (each) {
                if (each.routePointType == "COORDINATE") {
                    coordinateList.push({ lat: each.latitude, lng: each.longitude })
                }/* else if(each.routePointType == "MARKER"){
                   markerList.push({lat:each.latitude,lng:each.longitude})
               } */else if (each.routePointType == "CHAINAGE") {
                    chainageList.push({ latitude: each.latitude, longitude: each.longitude, type: each.routePointType, number: each.routePointNumber })
                }
            })

            if (coordinateList.length) {
                locations.map.setCenter(coordinateList[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateList,
                    geodesic: true,
                    strokeColor: '#FD7A24',
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                addClick(polyline)
            }

            /*  if(markerList.length){
                 locations.map.setCenter(markerList[0]);
                 createMarkers(markerList,'content/images/markersPoint.png',locations.map)
             } */

            if (chainageList.length) {
                locations.map.setCenter(chainageList[0]);
                createMarkers(chainageList, 'content/images/chainagePoints.png', locations.map)
            }
        }


        //Functions

        function addClick(item) {
            // Add a click event listener to the map
            item.addListener("click", function (event) {
                if (markers.length < 2) {
                    // Add a marker to the map
                    addMarker(event.latLng);

                    // Get the latitude and longitude of the clicked point
                    vm.pinLat = event.latLng.lat();
                    vm.pinLong = event.latLng.lng();
                } else {
                    console.log("Maximum 2 markers allowed.");
                }

                if(vm.isShowNearbyAdd && markersNearby.length < 2 && markers.length == 2){
                    addMarkerNearBy(event.latLng)
                }
            })
        }


        function createMarkers(arr, img, map) {
            arr.forEach(function (each) {
                var marker = new google.maps.Marker({
                    position: { lat: each.latitude, lng: each.longitude },
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: img,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                });

                google.maps.event.addListener(
                    marker,
                    "click",
                    (function () {
                        //var name = (each.name == (undefined || null)) ? " " : each.name
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        var userContent = document.createElement("div")

                        userContent.innerHTML = '<div class="infoWindowhead"></br></br>' +
                            /* "<b>Name : "+name+"</b></br>"+ */
                            "<b>Latitude : " + each.latitude + "</b></br>" +
                            "<b>Longitude : " + each.longitude + "</b></br>" +
                            "<b>Type : " + each.type + "</b></br>" +
                            "<b>Number : " + each.number + "</b></br>" +
                            "</br></div>"
                        infowindow.setContent(
                            userContent
                        );
                        infowindow.open(map);
                        infowindow.setPosition({
                            lat: each.latitude,
                            lng: each.longitude,
                        });
                        openedInfoWindow = infowindow;


                        if (markers.length < 2) {
                            btnAddSection = userContent.appendChild(
                                document.createElement("input")
                            );
                            btnAddSection.type = "button";

                            btnAddSection.value = "Add Section Point";

                            btnAddSection.className = "button";

                            btnAddSection.addEventListener("click", function () {
                                if (markers.length < 2) {
                                    // Add a marker to the map
                                    addMarker({
                                        lat: each.latitude,
                                        lng: each.longitude,
                                    });

                                    // Get the latitude and longitude of the clicked point
                                    vm.pinLat = each.latitude;
                                    vm.pinLong = each.longitude;
                                    openedInfoWindow.close();
                                } else {
                                    alert("From and To Section Exists");
                                }
                            })
                        }

                    }))


                arr.push(marker);
            })
        }

        function addMarker(location) {
            var markerText
            if (markers.length == 0) {
                markerText = labelFrom
            } else if (markers.length == 1) {
                markerText = labelTo
            }
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                icon: {
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                    url: "content/images/sections.png",
                    anchor: new google.maps.Point(
                        iconSize / 2,
                        -4
                    ),
                    labelOrigin: new google.maps.Point(25, 22),
                },
                label: {
                    text: markerText,
                },
                draggable: true
            });

            // Add the marker to the markers array
            markers.push(marker);
        }


        if (vm.trSection.id && vm.trSection.fromRoutePoint && vm.trSection.toRoutePoint) {
            addMarker({ lat: vm.trSection.fromRoutePoint.latitude, lng: vm.trSection.fromRoutePoint.longitude })
            addMarker({ lat: vm.trSection.toRoutePoint.latitude, lng: vm.trSection.toRoutePoint.longitude })
            locations.map.setCenter({ lat: vm.trSection.fromRoutePoint.latitude, lng: vm.trSection.fromRoutePoint.longitude });
        }

        vm.objectRouteImg = {}
        vm.objectRouteImg.routeId = vm.trSection.routeId
        vm.objectRouteImg.map = locations.map
        
         geoLocation(locations.map);

        vm.closeModal = function(id) {
            vm.getLocationCoordinateA = null
            vm.getLocationCoordinateB = null

            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        }

        vm.onLocationCoordinates = function() {
            var latlngA = vm.getLocationCoordinateA.split(",");
            var latlngB = vm.getLocationCoordinateB.split(",");

            addMarker({lat: parseFloat(latlngA[0]),lng: parseFloat(latlngA[1])})
            addMarker({lat: parseFloat(latlngB[0]),lng: parseFloat(latlngB[1])})

            locations.map.setCenter({lat: parseFloat(latlngB[0]),lng: parseFloat(latlngB[1])})
            vm.closeModal('modalLatLongs')
        }

        var markersNearby = []
        function addMarkerNearBy(location) {
            var markerText
            if (markersNearby.length == 0) {
                markerText = "From Nearby"
            } else if (markersNearby.length == 1) {
                markerText = "To Nearby"
            }
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                icon: {
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                    url: "content/images/pin24.png",
                    anchor: new google.maps.Point(
                        iconSize / 2,
                        -4
                    ),
                    labelOrigin: new google.maps.Point(25, 22),
                },
                label: {
                    text: markerText,
                },
                draggable: true
            });

            // Add the marker to the markers array
            markersNearby.push(marker);
        }

    }
})();
